import Block from "../../../base/Block";
import Registration from "../../../base/Registration";
import "./HeaderMenu.scss";
class HeaderMenu extends Block {
    static get blockName() {
        return "b-header-menu";
    }
    onInit() {
        const it = this;
        const btn = it.el.querySelector('.button');
        const loginForm = it.el.querySelector(`.${HeaderMenu.blockName}__login`);
        if (!btn || !loginForm) {
            return;
        }
        if (btn.classList.contains('login')) {
            const send = loginForm.querySelector(`.${HeaderMenu.blockName}__login-send`);
            btn.addEventListener('click', function () {
                loginForm.style.display = loginForm.style.display == 'block' ? 'none' : 'block';
            });
            send?.addEventListener('click', function () {
                const login = loginForm.querySelector(`.${HeaderMenu.blockName}__login-login`)?.value;
                const password = loginForm.querySelector(`.${HeaderMenu.blockName}__login-password`)?.value;
                fetch(`/api/login.php?login=${login}&password=${password}`).then(() => {
                    location.reload();
                });
            });
        }
        else if (btn.classList.contains('logout')) {
            btn.addEventListener('click', function () {
                fetch('/api/logout.php').then(() => {
                    location.reload();
                });
            });
        }
    }
}
Registration.register(HeaderMenu);
export default HeaderMenu;
