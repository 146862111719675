import "normalize.css";
import "../blocks/common/HeaderMenu/HeaderMenu";
import "./base.scss";
import Registration from "../base/Registration";
(function () {
    "use strict";
    document.addEventListener("DOMContentLoaded", () => {
        Registration.blocksInit();
    });
})();
