/**
 * Основной класс блоков
 *
 * @param { Element } el - элемент блока на странице
 */
class Block {
    el;
    static get blockName() {
        return "";
    }
    constructor(el) {
        this.el = el;
    }
    onInit() {
        //
    }
}
export default Block;
